<template>
  <span class="add-individual-to-list-button" v-if="tag && hasPerm('people.add_tag')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Ajouter à la liste {{ tag.name }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="individual-group"
            label="Membre"
            label-for="individual"
          >
            <individual-typeahead id="individual" @change="onIndividualChanged($event.individual)">
            </individual-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="comments-group"
            label="Commentaire"
            label-for="comments"
          >
            <b-form-input
              id="comments"
              type="text"
              v-model="comments"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import IndividualTypeahead from '@/components/Individual/IndividualTypeahead'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddIndividualsToList',
  mixins: [BackendMixin],
  components: {
    IndividualTypeahead,
  },
  props: {
    tag: Object,
    modalId: {
      type: String,
      default: 'bv-modal-add-individual-to-tag',
    },
  },
  data() {
    return {
      errorText: '',
      individual: null,
      comments: '',
    }
  },
  computed: {
    formInvalid() {
      return this.individual === null
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onIndividualChanged(individual) {
      this.individual = individual
    },
    async onSave() {
      if (this.individual) {
        this.errorText = ''
        let url = '/api/people/add-tags/'
        const backendApi = new BackendApi('post', url)
        const data = {
          'name': this.tag.name,
          'individual': this.individual.id,
          'comments': this.comments,
        }
        try {
          const resp = await backendApi.callApi(data)
          await this.addSuccess(resp.data.message)
          this.$bvModal.hide(this.modalId)
          this.$emit('done')
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
