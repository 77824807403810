<template>
  <span class="duplicate-tag-modal" v-if="tag && hasPerm('people.add_tag')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave()"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-title="okTitle"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Dupliquer ou fusionner la liste {{ tag.name }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            label="Sélectionnez une liste existante ou entrez le nom d'une nouvelle liste" label-for="nameTag"
            description="Si vous sélectionnez une liste existante, les membres seront ajoutés à cette liste"
          >
            <vue-bootstrap-typeahead
              :data="tags"
              ref="nameTagTypeAhead"
              v-model="tagName"
            ></vue-bootstrap-typeahead>
          </b-form-group>
          <div class="error" v-if="isSame">
            <b>Vous ne pouvez pas dupliquer la liste existante avec le même nom</b>
          </div>
          <div class="warning" v-if="tagName && !isSame && tagExists">
            <b>Vous allez fusionner la liste "{{ tag.name }}" dans la liste "{{ tagName }}"</b><br />
            Les membres de "{{ tag.name }}" deviendront membre de "{{ tagName }}"
          </div>
          <div class="warning" v-if="tagName && !isSame && !tagExists">
            <b>Vous allez dupliquer la liste "{{ tag.name }}" sous le nom "{{ tagName }}"</b><br />
            Les membres de "{{ tag.name }}" deviendront membre de "{{ tagName }}"
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'DuplicateTagModal',
  mixins: [BackendMixin],
  data() {
    return {
      tagName: '',
      errorText: '',
      tagId: null,
    }
  },
  props: {
    modalId: {
      type: String,
      default: 'bv-modal-duplicate-tag',
    },
    tag: Object,
    tags: Array,
    individuals: Array,
  },
  computed: {
    formInvalid() {
      return (this.tagName === '') || (this.tag.name === this.tagName)
    },
    isSame() {
      return this.tag.name === this.tagName
    },
    tagExists() {
      return this.tags.indexOf(this.tagName) >= 0
    },
    okTitle() {
      return this.tagExists ? 'Fusionner' : 'Dupliquer'
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onSave() {
      let url = '/api/people/duplicate-list-tag/'
      const backendApi = new BackendApi('post', url)
      const data = {
        name: this.tagName,
        individuals: this.individuals,
      }
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        this.tagId = resp.data.tag_id
        this.tagName = ''
        this.$bvModal.hide(this.modalId)
        this.$emit('done', this.tagId)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.error {
  background: #eee;
  color: #cc0000;
  padding: 4px 10px;
}
.warning {
  background: #eee;
  color: #222;
  padding: 4px 10px;
}
</style>
